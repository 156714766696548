export const stadiums: Record<string, string> = {
  ASC: require('../images/stadiums/ASC.jpg'),
  ASM: require('../images/stadiums/ASM.jpg'),
  ASSE: require('../images/stadiums/ASSE.jpg'),
  DFC: require('../images/stadiums/DFC.jpg'),
  EAG: require('../images/stadiums/EAG.jpg'),
  FCGB: require('../images/stadiums/FCGB.jpg'),
  FCN: require('../images/stadiums/FCN.jpg'),
  LOSC: require('../images/stadiums/LOSC.jpg'),
  MHSC: require('../images/stadiums/MHSC.jpg'),
  NO: require('../images/stadiums/NO.jpg'),
  OGC: require('../images/stadiums/OGC.jpg'),
  OL: require('../images/stadiums/OL.jpg'),
  OM: require('../images/stadiums/OM.jpg'),
  PSG: require('../images/stadiums/PSG.jpg'),
  RCSA: require('../images/stadiums/RCSA.jpg'),
  SCO: require('../images/stadiums/SCO.jpg'),
  SDR: require('../images/stadiums/SDR.jpg'),
  SMC: require('../images/stadiums/SMC.jpg'),
  SRFC: require('../images/stadiums/SRFC.jpg'),
  TFC: require('../images/stadiums/TFC.jpg'),
}
