import React, { ReactNode } from 'react'
import { Omit } from 'utility-types'

import styled from '../../styles/styled'
import { Color } from '../../styles/theme'

import Text from '../Text'

type StyledBadgeProps = Omit<BadgeProps, 'children'>

const StyledBadge = styled.div<StyledBadgeProps>`
  border-radius: 4px;
  background-color: ${({ theme, color }) => color && theme.colors[color]};
  padding: ${({ isBig }) => (isBig ? '8px 12px' : '4px')};
  white-space: nowrap;
`

interface BadgeProps {
  children: ReactNode
  color?: Color
  isBig?: boolean
  onClick?: () => void
}

const Badge = ({ children, color, isBig, ...props }: BadgeProps) => (
  <StyledBadge color={color} isBig={isBig} {...props}>
    <Text
      color={color === 'lightGrey' ? 'grey' : 'white'}
      weight={isBig ? 600 : 500}
      size={isBig ? 13 : 16}
    >
      {children}
    </Text>
  </StyledBadge>
)

Badge.defaultProps = {
  color: 'blue',
} as BadgeProps

export default Badge
