import React, { useState, Fragment } from 'react'
import ReactGA from 'react-ga'

import { Link } from '@reach/router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '../../styles/styled'

import Text from '../Text'
import Container from '../Container'
import Modal from '../Modal'

const StyledHeader = styled.header`
  display: flex;
  height: 40px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.blue};
  position: sticky;
  top: 0px;
  z-index: 100;
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;
`

const StyledRightContainer = styled.div`
  margin-left: auto;
  display: flex;
  cursor: pointer;
`

const StyledLogo = styled(Link)`
  display: flex;
  align-items: center;
  width: 94px;
  > img {
    width: 100%;
  }
`

const StyledText = styled(Text)`
  & + & {
    margin-top: 16px;
  }
`

const StyledLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: underline;
`

const Header = () => {
  const [isModalOpened, setModalOpened] = useState(false)

  const openModal = () => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.modalview('/about')
    }
    setModalOpened(true)
  }

  return (
    <Fragment>
      <StyledHeader>
        <StyledContainer>
          <StyledLogo to="/">
            <img src={require('../../images/logo.svg')} alt="ballistic" />
          </StyledLogo>
          <StyledRightContainer onClick={() => openModal()}>
            <FontAwesomeIcon icon="question-circle" size="lg" />
          </StyledRightContainer>
        </StyledContainer>
      </StyledHeader>
      <Modal
        isOpen={isModalOpened}
        onRequestClose={() => setModalOpened(false)}
      >
        <StyledText weight={600} size={20}>
          Qu’est-ce que c’est Ballistic ?
        </StyledText>
        <StyledText size={13} color="darkGrey">
          Ballistic est un site de décryptage du football par les statistiques.
        </StyledText>
        <StyledText size={13} color="darkGrey">
          Nous utilisons une trentaine de statistiques, comme les buts marqués,
          les passes décisives, les fautes, les tacles décisifs, etc. pour
          évaluer la performance des joueurs après leur match, via une note sur
          10.
        </StyledText>
        <StyledText size={13} color="darkGrey">
          Notre vision est de fournir un outil objectif d’analyse des
          performances de vos joueurs et équipes préférées, à destination des
          passionnés, des blogueurs, analystes et parieurs sportifs.
        </StyledText>
        <StyledText size={13} color="darkGrey">
          Vous pouvez nous écrire à{' '}
          <StyledLink href="mailto:helloballistic@gmail.com">
            helloballistic@gmail.com
          </StyledLink>{' '}
          ou sur{' '}
          <StyledLink href="https://twitter.com/ballisticfoot">
            Twitter
          </StyledLink>{' '}
          et{' '}
          <StyledLink href="https://www.facebook.com/ballisticfoot">
            Facebook
          </StyledLink>{' '}
          pour toute question ou remarque concernant le projet.
        </StyledText>
      </Modal>
    </Fragment>
  )
}

export default Header
