import React, { ReactNode } from 'react'
import map from 'ramda/es/map'
import prop from 'ramda/es/prop'
import {
  VictoryLine,
  VictoryChart,
  VictoryAxis,
  VictoryContainer,
  VictoryLabel,
} from 'victory'

import styled from '../../../styles/styled'
import { colors } from '../../../styles/theme'

import Text from '../../Text'

const fakeData = [
  { matchday: 1, value: 2 },
  { matchday: 2, value: 3 },
  { matchday: 3, value: 1 },
  { matchday: 4, value: 6 },
  { matchday: 5, value: 3 },
  { matchday: 6, value: 2 },
]

const StyledLabel = styled(Text)`
  margin-top: 4px;
`

interface LineProps {
  label?: ReactNode
  data?: Array<{ matchday: number; value: number }>
}

const Line = ({ label, data = fakeData, ...props }: LineProps) => {
  // @ts-ignore
  const maxValue = Math.max(...map(prop('value'), data)) || 5

  return (
    <div {...props}>
      <VictoryChart
        domainPadding={{ y: [5, 10] }}
        padding={{ top: 15, left: 10, right: 10 }}
        width={140}
        height={65}
        containerComponent={
          <VictoryContainer responsive={false} style={{ marginLeft: -10 }} />
        }
        domain={{ y: [-1, maxValue] }}
      >
        <VictoryAxis
          orientation="top"
          tickValues={[0, 1, 2, 3, 4, 5]}
          tickFormat={(x, index) => data[index] && `J${data[index].matchday}`}
          style={{
            tickLabels: {
              fill: colors.lightGrey,
              fontSize: 12,
            },
            axis: { stroke: 'none' },
            grid: { stroke: colors.lightGrey },
          }}
          offsetY={15}
          tickLabelComponent={<VictoryLabel dy={10} />}
        />
        <VictoryLine
          data={data}
          y="value"
          interpolation="natural"
          style={{
            data: { stroke: colors.darkBlue, strokeWidth: 2 },
          }}
          padding={100}
        />
      </VictoryChart>
      {label && <StyledLabel preset="label">{label}</StyledLabel>}
    </div>
  )
}

export default Line
