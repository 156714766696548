import { combineReducers, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { StateType } from 'typesafe-actions'

import summary, { SummaryAction } from './summary/reducer'
import competition, { CompetitionAction } from './competition/reducer'
import players, { PlayersAction } from './players/reducer'
import matches, { MatchesAction } from './matches/reducer'
import history, { HistoryAction } from './history/reducer'

export type RootAction =
  | SummaryAction
  | CompetitionAction
  | PlayersAction
  | MatchesAction
  | HistoryAction

const rootReducer = combineReducers({
  summary,
  competition,
  players,
  matches,
  history,
})

export type RootState = StateType<typeof rootReducer>

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>

export default rootReducer
