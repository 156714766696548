export const colors = {
  black: '#000000',
  white: '#ffffff',

  red: '#ff645d',
  green: '#1ac978',
  yellow: '#f9c946',

  darkBlue: '#4246c6',
  blue: '#3c40c6',

  darkGrey: '#494949',
  grey: '#a4a4a5',
  lightGrey: '#f0f0f0',

  darkNavy: '#8a99ac',
  navy: '#d1d5da',
  lightNavy: '#ebeff4',
}

export type Colors = typeof colors
export type Color = keyof Colors

const theme = {
  colors,
}

export interface Theme {
  colors: Colors
}

export default theme
