import styled from '../../styles/styled'
import media from '../../styles/media'

export const CONTAINER_WIDTH = 960

const Container = styled.div`
  width: ${CONTAINER_WIDTH}px;
  margin: 24px auto;
  ${media.desktop`
    width: 100%;
    padding: 0px 16px;
  `}
`

export default Container
