import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { Competition } from '../../../../server/src/services/competition'

export type CompetitionAction = ActionType<typeof actions>

export type CompetitionState = {
  selectedMatchday: number
} & Competition

const defaultState: CompetitionState = {
  currentMatchday: 0,
  selectedMatchday: 0,
}

const reducer = reducerWithInitialState(defaultState)
  .case(actions.fetchCompetition.done, (state, { result: competition }) => ({
    selectedMatchday: competition.currentMatchday,
    ...competition,
  }))
  .case(actions.setSelectedMatchday, (state, matchday) => ({
    ...state,
    selectedMatchday: matchday,
  }))

export default reducer
