import { css } from 'styled-components'

const sizes = {
  giant: 1200,
  desktop: 992,
  tablet: 768,
  phone: 576,
}

type Media = { [P in keyof typeof sizes]: any }

const media: Media = Object.entries(sizes).reduce(
  (accumulator, [label, size]) => {
    const emSize = size / 16
    accumulator[label] = (...args: any) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)};
      }
    `
    return accumulator
  },
  {} as any
)

export default media
