import { Dispatch, Middleware, AnyAction } from 'redux'
import actionCreatorFactory, { AsyncActionCreators } from 'typescript-fsa'
import qs from 'query-string'

const actionCreator = actionCreatorFactory()

export interface Request {
  method: string
  path: (params: any) => string
  body?: (params: any) => object
  query?: (params: any) => object
}

export type HttpAction<P, R> = AsyncActionCreators<P, R> & {
  request: Request
  payload: any
}

export function httpActionCreator<P, R>(
  name: string,
  request: Request
): ((payload?: P) => HttpAction<P, R>) & AsyncActionCreators<P, R> {
  const creator = actionCreator.async<P, R>(name)
  const action: HttpAction<P, R> = { ...creator, request, payload: null }
  return Object.assign(
    (payload?: P) => Object.assign({}, action, { payload }),
    creator
  )
}

export function isHttpAction(action: any): action is HttpAction<any, any> {
  return (
    action.request &&
    typeof action.request.method === 'string' &&
    action.started &&
    action.done
  )
}

async function performRequest(
  dispatch: Dispatch<AnyAction>,
  action: HttpAction<any, any>,
  baseUrl: string
): Promise<void> {
  const { method, path, body, query } = action.request

  let url = baseUrl + path(action.payload)
  const opts: RequestInit = { method }

  if (body) {
    opts.body = JSON.stringify(body(action.payload))
    opts.headers = { 'Content-Type': 'application/json' }
  }

  if (query) {
    url = `${url}?${qs.stringify(query(action.payload))}`
  }

  try {
    const response = await fetch(url, opts)
    if (response.ok) {
      const result = await response.json()
      dispatch(action.done({ params: action.payload, result }))
    } else {
      const f = {
        error: new Error(response.statusText),
        params: action.payload,
      }
      dispatch(action.failed(f))
    }
  } catch (error) {
    dispatch(action.failed({ error, params: action.payload }))
  }
}

export const createHttpMiddleware = (baseUrl: string): Middleware => {
  return ({ dispatch }) => (next: Dispatch<AnyAction>) => (action: any) => {
    if (isHttpAction(action)) {
      const started = next(action.started(action.payload))
      performRequest(dispatch, action, baseUrl)
      return started
    }
    return next(action)
  }
}
