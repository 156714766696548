import React from 'react'
import { Team } from 'shared/types'

import styled from '../../styles/styled'

import Text from '../Text'
import TeamLogo from '../TeamLogo'

const StyledCenterContainer = styled.div`
  display: flex;
  align-items: center;
`

interface PlayerTeamProps {
  team: Team
}

const PlayerTeam = ({ team }: PlayerTeamProps) => (
  <StyledCenterContainer>
    <TeamLogo team={team} size={16} />
    <Text size={13} color="darkGrey">
      {team.name}
    </Text>
  </StyledCenterContainer>
)

export default PlayerTeam
