import actionCreatorFactory from 'typescript-fsa'
import { StatisticType } from 'shared/types'

import { PlayerMatchResponse } from '../../../../server/src/services/players/match'
import { PlayerMatchStatisticsResponse } from '../../../../server/src/services/players/match/statistics'

import { httpActionCreator } from '../helpers'

const actionCreator = actionCreatorFactory()

interface FetchMatchParams {
  playerId: string
  matchId: string
}

export const setSelectedMatch = actionCreator<FetchMatchParams | null>(
  'SET_SELECTED_MATCH'
)

export const fetchMatchDetails = httpActionCreator<
  FetchMatchParams,
  PlayerMatchResponse
>('FETCH_MATCH_DETAILS', {
  method: 'get',
  path: ({ playerId, matchId }) => `/players/${playerId}/matches/${matchId}`,
})

interface FetchMatchStatisticsParams {
  playerId: string
  matchId: string
  type: StatisticType
}

export const fetchMatchStatistics = httpActionCreator<
  FetchMatchStatisticsParams,
  PlayerMatchStatisticsResponse
>('FETCH_MATCH_STATISTICS', {
  method: 'get',
  path: ({ playerId, matchId }) =>
    `/players/${playerId}/matches/${matchId}/statistics`,
  query: ({ type }) => ({ type }),
})
