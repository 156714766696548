import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PlayerMatch } from 'shared/types'

import styled from '../../../../styles/styled'
import { matchPosition } from '../../../../helpers/display'
import { setSelectedMatch } from '../../../../store/matches/actions'

import Text from '../../../../components/Text'
import Card from '../../../../components/Card'
import Avatar from '../../../../components/Avatar'
import MatchResult from '../../../../components/MatchResult'
import Note from '../../../../components/Note'
import MatchEvents from '../../../../components/MatchEvents'

const StyledFirstRow = styled.div`
  display: flex;
  flex: 1;
`

const StyledInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 12px;
  margin-right: 4px;
`

const StyledNote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-left: auto;
`

const StyledSecondRow = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`

const StyledMatchEvents = styled(MatchEvents)`
  margin-left: auto;
  align-items: flex-end;
`

interface PlayerCardProps {
  player: PlayerMatch
  setSelectedMatch: typeof setSelectedMatch
}

class PlayerCard extends Component<PlayerCardProps> {
  render() {
    const { player, setSelectedMatch } = this.props
    const {
      id,
      name,
      playedPosition,
      team,
      ballistat,
      match,
      isManOfTheMatch,
      events,
    } = player

    return (
      <Card
        height={180}
        direction="column"
        type="shadow"
        onClick={() => setSelectedMatch({ playerId: id, matchId: match.id })}
      >
        <StyledFirstRow>
          <Avatar player={player} />
          <StyledInfos>
            <Text weight={600}>{name}</Text>
            <Text weight={600} size={13} color="darkNavy">
              {team.shortName}
            </Text>
            <Text size={13} color="darkNavy">
              {matchPosition[playedPosition]}
            </Text>
          </StyledInfos>
          <StyledNote>
            <Note isBallistic value={ballistat} label="Note Ballistic" />
          </StyledNote>
        </StyledFirstRow>
        <StyledSecondRow>
          <MatchResult match={match} player={player} />
          <StyledMatchEvents
            isManOfTheMatch={isManOfTheMatch}
            events={events}
          />
        </StyledSecondRow>
      </Card>
    )
  }
}

const mapDispatchToProps = {
  setSelectedMatch,
}

export default connect(
  null,
  mapDispatchToProps
)(PlayerCard)
