import '@babel/polyfill'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'

import './fonts/index.css'
import './index.css'
import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))
