import styled, { css } from '../../styles/styled'
import media from '../../styles/media'

interface CardProps {
  direction?: 'row' | 'column'
  type: 'border' | 'shadow'
  height?: number
}

const Card = styled.div.attrs(({ direction, type, height }: CardProps) => ({
  direction: direction || 'row',
  type: type || 'border',
  height,
}))`
  display: flex;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  height: ${({ height }) => (height ? `${height}px` : '')};
  flex-direction: ${({ direction }) => direction};
  ${({ direction }) =>
    direction === 'row'
      ? css`
          align-items: center;
        `
      : css`
          justify-content: center;
        `}
  ${media.phone`
    padding: 16px;
  `}
  ${({ type }) =>
    type === 'shadow'
      ? css`
          cursor: pointer;
          box-shadow: 0px 3px 6px 1px #dcdcdc;
          :hover {
            box-shadow: 0px 3px 10px 0px #adadad;
          }
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.navy};
        `}
`

export default Card
