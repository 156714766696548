import React, { ReactNode } from 'react'

import styled from '../../styles/styled'

import Text, { FontSize } from '../Text'

const StyledContainer = styled.div`
  margin: 24px 0px;
`

const StyledTitle = styled(Text)`
  margin-bottom: 8px;
`

interface ContentGroupProps {
  title: string
  titleSize?: FontSize
  children: ReactNode
}

const ContentGroup = ({ title, titleSize, children }: ContentGroupProps) => (
  <StyledContainer>
    <StyledTitle weight={500} size={titleSize}>
      {title}
    </StyledTitle>
    {children}
  </StyledContainer>
)

export default ContentGroup
