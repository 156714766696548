import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { connect } from 'react-redux'

import ContentGroup from '../../../components/ContentGroup'
import MatchRecap from '../../../components/MatchRecap'
import { fetchPlayerHistory } from '../../../store/history/actions'
import { RootState } from '../../../store/reducer'
import { HistoryState } from '../../../store/history/reducer'
import { PlayersState } from '../../../store/players/reducer'

interface HistoryProps {
  player?: PlayersState[string]
  history?: HistoryState[string]
  fetchPlayerHistory: typeof fetchPlayerHistory
}

type OwnProps = RouteComponentProps<{ playerId: string }>

type Props = HistoryProps & OwnProps

const History: FC<Props> = ({
  playerId,
  player,
  history,
  fetchPlayerHistory,
}) => {
  useEffect(() => {
    fetchPlayerHistory(playerId)
  }, [playerId])

  if (!history || !player) return null

  return (
    <ContentGroup title="Saison 2018/2019">
      {history.map(match => (
        <MatchRecap key={match.id} match={match} player={player} />
      ))}
    </ContentGroup>
  )
}

const mapStateToProps = (
  { players, history }: RootState,
  { playerId }: OwnProps
) => ({
  player: playerId ? players[playerId] : undefined,
  history: playerId ? history[playerId] : undefined,
})

const mapDispatchToProps = {
  fetchPlayerHistory,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History)
