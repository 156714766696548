import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '../../styles/styled'

import Container from '../Container'
import Text from '../Text'

const StyledContainer = styled(Container)`
  margin: 32px auto 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const StyledIconsContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`

const StyledIcon = styled.a`
  display: flex;
  color: ${({ theme }) => theme.colors.darkNavy};
  & + & {
    margin-left: 8px;
  }
`

const Footer = () => (
  <StyledContainer>
    <StyledIconsContainer>
      <StyledIcon
        href="https://www.facebook.com/ballisticfoot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'facebook']} size="lg" />
      </StyledIcon>
      <StyledIcon
        href="https://twitter.com/ballisticfoot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />
      </StyledIcon>
      <StyledIcon
        href="https://www.instagram.com/ballisticfoot"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />
      </StyledIcon>
    </StyledIconsContainer>
    <Text size={13} color="darkNavy" weight={500}>
      © 2019 Ballistic • Décrypter le football par les statistiques
    </Text>
    <Text size={13} color="darkNavy">
      Made with 🔥 in Paris
    </Text>
  </StyledContainer>
)

export default Footer
