import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

import rootReducer from './reducer'
import { createHttpMiddleware } from './helpers'

const isDevelopment = process.env.NODE_ENV === 'development'

export const API_BASE_URL = isDevelopment
  ? 'http://localhost:3001/api'
  : `${window.location.origin}/api`

const configureStore = (initialState?: {}) => {
  // configure middlewares
  const middlewares = [thunk, createHttpMiddleware(API_BASE_URL)]
  if (isDevelopment) {
    const logger = createLogger({
      collapsed: () => true,
    })
    middlewares.push(logger)
  }
  // compose enhancers
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))
  // create store
  return createStore(rootReducer, initialState!, enhancer)
}

const store = configureStore()

export default store
