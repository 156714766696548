import React, { Component } from 'react'
import { connect } from 'react-redux'
import path from 'ramda/es/path'
import pathOr from 'ramda/es/pathOr'
import { DetailedPlayerMatch, RankingType } from 'shared/types'
import { Link } from '@reach/router'

import styled from '../../../styles/styled'

import { RootState } from '../../../store/reducer'
import {
  fetchMatchDetails,
  setSelectedMatch,
} from '../../../store/matches/actions'
import { MatchesState } from '../../../store/matches/reducer'

import { matchPosition } from '../../../helpers/display'

import Modal from '../../../components/Modal'
import Card from '../../../components/Card'
import Avatar from '../../../components/Avatar'
import Text from '../../../components/Text'
import PlayerTeam from '../../../components/PlayerTeam'
import MatchResult from '../../../components/MatchResult'
import MatchEvents from '../../../components/MatchEvents'
import Note from '../../../components/Note'
import Bars from '../../../components/Graph/Bars'
import DetailedStats from '../../../components/DetailedStats'
import media from '../../../styles/media'
import { getRanking, getRankingLabel } from '../../../helpers/ranking'

const StyledBasicInfos = styled.div`
  display: flex;
  align-items: center;
  grid-area: player;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 12px;

  ${media.phone`
    margin-bottom: 12px;
  `}
`

const StyledMatchResult = styled(MatchResult)`
  grid-area: match;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 12px;

  ${media.phone`
    border-bottom: none;
    padding-bottom: 0;
  `}
`

const StyledMatchEvents = styled(MatchEvents)`
  grid-area: events;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-bottom: 12px;

  ${media.phone`
    justify-content: flex-start;
    border-bottom: none;
    padding-bottom: 0;
  `}
`

const StyledAvatar = styled(Avatar)`
  margin-right: 12px;
`

const StyledNote = styled(Note)`
  grid-area: note;
  display: flex;
  flex-direction: column;
`

const StyledMatchRanking = styled(Bars)<{ type: RankingType }>`
  grid-area: ${type => `${type}-ranking`};
`

const StyledMinutes = styled(Note)`
  grid-area: minutes;
  display: flex;
  flex-direction: column;
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    'player player match events'
    'note match-ranking matchday-ranking minutes';

  ${media.phone`
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 12px;
    grid-template-areas:
      'player player'
      'match minutes'
      'note events'
      'match-ranking matchday-ranking';
  `}
`

interface MatchModalProps {
  selectedMatch: MatchesState['selectedMatch']
  fetchMatchDetails: typeof fetchMatchDetails
  setSelectedMatch: typeof setSelectedMatch
  details: DetailedPlayerMatch | null
}

class MatchModal extends Component<MatchModalProps> {
  componentDidUpdate(prevProps: MatchModalProps) {
    const { selectedMatch, fetchMatchDetails } = this.props

    if (
      selectedMatch &&
      path(['matchId'], selectedMatch) !==
        path(['selectedMatch', 'matchId'], prevProps)
    ) {
      const { playerId, matchId } = selectedMatch
      fetchMatchDetails({ playerId, matchId })
    }
  }

  render() {
    const { details, setSelectedMatch } = this.props

    if (!details) return null

    const matchRanking = getRanking(details.ranking, 'match')
    const matchdayRanking = getRanking(details.ranking, 'matchday')

    return (
      <Modal
        isOpen={!!(details && details.id)}
        onRequestClose={() => setSelectedMatch(null)}
        background="grey"
      >
        {!!details && (
          <>
            <Card direction="column">
              <StyledContainer>
                <StyledBasicInfos>
                  <StyledAvatar player={details} size={80} />
                  <div>
                    <Link
                      to={`/players/${details.id}/history`}
                      onClick={() => setSelectedMatch(null)}
                    >
                      <Text size={20} weight={600}>
                        {details.name}
                      </Text>
                    </Link>
                    <Text size={13} color="darkGrey">
                      {matchPosition[details.playedPosition]}
                    </Text>
                    <PlayerTeam team={details.team} />
                  </div>
                </StyledBasicInfos>
                <StyledMatchResult match={details.match} player={details} />
                <StyledMatchEvents
                  isManOfTheMatch={details.isManOfTheMatch}
                  events={details.events}
                />
                <StyledNote
                  isBallistic
                  value={details.ballistat}
                  label="Note Ballistic"
                />
                {details.ranking.map(({ type, value, distribution }) => (
                  <StyledMatchRanking
                    key={type}
                    type={type}
                    statName="ballistat"
                    data={distribution}
                    value={details.ballistat}
                    label={getRankingLabel(type, value, details)}
                  />
                ))}
                <StyledMinutes
                  value={details.minutesPlayed}
                  label="Minutes jouées"
                  precision={0}
                />
              </StyledContainer>
            </Card>
            <DetailedStats player={details} matchId={details.match.id} />
          </>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = ({ matches }: RootState) => {
  const { selectedMatch, matchesByPlayers } = matches

  return {
    selectedMatch,
    details: selectedMatch
      ? pathOr(
          null,
          [selectedMatch.playerId, selectedMatch.matchId, 'details'],
          matchesByPlayers
        )
      : null,
  }
}

const mapDispatchToProps = {
  fetchMatchDetails,
  setSelectedMatch,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchModal)
