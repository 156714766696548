import React, { Component } from 'react'
import { Router } from '@reach/router'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faTriangle } from '@fortawesome/pro-solid-svg-icons/faTriangle'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'

import store from './store'
import theme from './styles/theme'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './containers/Home'
import Player from './containers/Player'
import MatchModal from './containers/Modals/Match'

library.add(
  faTriangle,
  faTimes,
  faQuestionCircle,
  faAngleLeft,
  faAngleRight,
  faFacebook,
  faTwitter,
  faInstagram
)

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-132373822-1')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <>
            <Header />
            <Router primary={false}>
              <Home path="/" />
              <Player path="/players/:playerId/*" />
            </Router>
            <Footer />
            <MatchModal />
          </>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default App
