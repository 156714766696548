import { StatisticType } from 'shared/types'

import { PlayerResponse } from '../../../../server/src/services/players/id'
import { PlayerStatisticsResponse } from '../../../../server/src/services/players/overview/statistics'

import { httpActionCreator } from '../helpers'

export const fetchPlayer = httpActionCreator<string, PlayerResponse>(
  'FETCH_PLAYER',
  {
    method: 'get',
    path: playerId => `/players/${playerId}`,
  }
)

export const fetchPlayerOverviewStats = httpActionCreator<
  { playerId: string; type: StatisticType },
  PlayerStatisticsResponse
>('FETCH_PLAYER_OVERVIEW_STATS', {
  method: 'get',
  path: ({ playerId }) => `/players/${playerId}/overview/statistics`,
  query: ({ type }) => ({ type }),
})
