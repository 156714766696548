import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from '@reach/router'

import { RootState } from '../../store/reducer'
import { fetchCompetition } from '../../store/competition/actions'
import { fetchSummary } from '../../store/summary/actions'
import { SummaryState } from '../../store/summary/reducer'

import styled from '../../styles/styled'
import Text from '../../components/Text'
import Container from '../../components/Container'

import MatchDaySelector from './components/MatchDaySelector'
import PlayerGroup from './components/PlayerGroup'

const StyledHeader = styled.div`
  display: flex;
  height: 40px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.navy};
  position: sticky;
  top: 40px;
  z-index: 100;
`

const StyledHeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin: 0 auto;
`

interface StateProps {
  summary: SummaryState[number]
}

interface DispatchProps {
  fetchCompetition: typeof fetchCompetition
  fetchSummary: () => void
}

type HomeProps = RouteComponentProps & DispatchProps & StateProps

class Home extends Component<HomeProps> {
  componentDidMount() {
    this.props.fetchCompetition()
    this.props.fetchSummary()
  }

  render() {
    const { summary } = this.props
    const { top = [], flop = [] } = summary

    return (
      <Fragment>
        <StyledHeader>
          <StyledHeaderContainer>
            <Text weight={600} size={16}>
              Ligue 1
            </Text>
            <MatchDaySelector />
          </StyledHeaderContainer>
        </StyledHeader>
        <Container>
          <PlayerGroup title="Top 10 👍" players={top} />
          <PlayerGroup title="Flop 10 👎" players={flop} />
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ summary, competition }: RootState) => ({
  summary: summary[competition.selectedMatchday] || {},
})

const mapDispatchToProps = {
  fetchCompetition,
  fetchSummary,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
