import React from 'react'
import Tippy from '@tippy.js/react'
import { Event } from 'shared/types'

import styled from '../../styles/styled'
import { eventName } from '../../helpers/display'

interface IconProps {
  name: Event | 'isManOfTheMatch'
  count?: number
}

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 25px;
`

const StyledIcon = styled.img``

const StyledCount = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  font-size: 10px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};
  line-height: 1;
`

const StyledPenaltyScoredIcon = styled.img`
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%);
`

const StyledPenaltyMissedIcon = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
`

const getIcon = (name: IconProps['name']) => {
  switch (name) {
    case 'isManOfTheMatch':
      return (
        <StyledIcon
          src={require('../../images/icons/man_of_the_match.svg')}
          alt="man_of_the_match"
        />
      )
    case 'assist':
      return (
        <StyledIcon
          src={require('../../images/icons/assist.svg')}
          alt="assist"
        />
      )
    case 'yellowCard':
      return (
        <StyledIcon
          src={require('../../images/icons/yellow_card.svg')}
          alt="yellow_card"
        />
      )
    case 'secondYellow':
      return (
        <>
          <StyledCount>2</StyledCount>
          <StyledIcon
            src={require('../../images/icons/yellow_card.svg')}
            alt="yellow_card"
          />
        </>
      )
    case 'redCard':
      return (
        <StyledIcon
          src={require('../../images/icons/red_card.svg')}
          alt="red_card"
        />
      )
    case 'tackleLastMan':
      return (
        <StyledIcon
          src={require('../../images/icons/tackle_last_man.svg')}
          alt="tackle_last_man"
        />
      )
    case 'clearanceOffTheLine':
      return (
        <StyledIcon
          src={require('../../images/icons/clearance_off_the_line.svg')}
          alt="clearance_off_the_line"
        />
      )
    case 'errorLeadsToGoal':
      return (
        <StyledIcon
          src={require('../../images/icons/cross_red.svg')}
          alt="cross_red"
        />
      )
    case 'keeperPenaltySaved':
      return (
        <StyledIcon
          src={require('../../images/icons/keeper_penalty_saved.svg')}
          alt="keeper_penalty_saved"
        />
      )
    case 'goalNormal':
      return (
        <StyledIcon
          src={require('../../images/icons/ball_blue.svg')}
          alt="ball_blue"
        />
      )
    case 'goalOwn':
      return (
        <StyledIcon
          src={require('../../images/icons/ball_red.svg')}
          alt="ball_red"
        />
      )
    case 'penaltyScored':
      return (
        <>
          <StyledIcon
            src={require('../../images/icons/ball_blue.svg')}
            alt="ball_blue"
          />
          <StyledPenaltyScoredIcon
            src={require('../../images/icons/penalty.svg')}
            alt="penalty"
          />
        </>
      )
    case 'penaltyMissed':
      return (
        <>
          <StyledIcon src={require('../../images/icons/ball_blue.svg')} />
          <StyledPenaltyMissedIcon
            src={require('../../images/icons/cross_red.svg')}
            alt="cross_red"
          />
        </>
      )
    default:
      throw new Error(`The icon "${name}" doesn't exist`)
  }
}

const Icon = ({ name, count, ...props }: IconProps) => {
  return (
    <Tippy
      content={eventName[name]}
      animateFill={false}
      animation="fade"
      arrow
      theme="ballistic"
    >
      <StyledContainer {...props}>
        {count && count > 1 && <StyledCount>{count}</StyledCount>}
        {getIcon(name)}
      </StyledContainer>
    </Tippy>
  )
}

export default Icon
