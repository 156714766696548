import { httpActionCreator } from '../helpers'

import { PlayersSummaryResponse } from '../../../../server/src/services/players/summary'

import { ThunkResult } from '../reducer'

export const apiFetchSummary = httpActionCreator<
  number,
  PlayersSummaryResponse
>('FETCH_SUMMARY', {
  method: 'get',
  path: matchday =>
    matchday ? `/players/summary?matchday=${matchday}` : `/players/summary`,
})

export const fetchSummary = (matchday?: number): ThunkResult<void> => (
  dispatch,
  getState
) => {
  const { summary } = getState()

  if (matchday && summary[matchday]) return

  dispatch(apiFetchSummary(matchday))
}
