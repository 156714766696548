import React from 'react'
import allStatistics from 'shared/dist/statistics'
import times from 'ramda/es/times'
import findLast from 'ramda/es/findLast'
import {
  VictoryChart,
  VictoryContainer,
  VictoryAxis,
  VictoryBar,
} from 'victory'

import styled from '../../../styles/styled'
import { colors } from '../../../styles/theme'

import Text from '../../Text'

const fakeData = [
  { range: 0, count: 3 },
  { range: 1, count: 3 },
  { range: 2, count: 10 },
  { range: 3, count: 6 },
  { range: 4, count: 9 },
  { range: 5, count: 7 },
  { range: 6, count: 8 },
  { range: 7, count: 6 },
  { range: 8, count: 3 },
  { range: 9, count: 2 },
]

const StyledLabel = styled(Text)`
  margin-top: 4px;
`

interface BarsProps {
  statName: string
  label?: string
  data?: Array<{ count: number; range: number }>
  value: number
}

const getTickValues = (statName: string) => {
  if (statName === 'ballistat') {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  }

  const { bucketSteps } = allStatistics.find(s => s.name === statName)!
  const boundaries = [...times(n => n * bucketSteps, 10)]
  return boundaries
}

const Bars = ({
  label,
  statName,
  data = fakeData,
  value,
  ...props
}: BarsProps) => {
  const tickValues = getTickValues(statName)
  const playerRange = findLast(range => value >= range, tickValues)

  return (
    <div {...props}>
      <VictoryChart
        domainPadding={{ x: 10 }}
        padding={{ top: 15, left: 0, right: 0, bottom: 0 }}
        width={140}
        height={65}
        containerComponent={<VictoryContainer responsive={false} />}
      >
        <VictoryAxis
          tickValues={tickValues}
          tickFormat={() => ''}
          style={{ axis: { stroke: 'none' }, grid: { stroke: 'none' } }}
        />
        <VictoryBar
          data={data}
          x="range"
          y="count"
          style={{
            data: {
              fill: ({ range }) => {
                return range === playerRange ? colors.blue : colors.lightNavy
              },
            },
          }}
          barWidth={10}
          cornerRadius={2}
          alignment="start"
        />
      </VictoryChart>
      {label && <StyledLabel preset="label">{label}</StyledLabel>}
    </div>
  )
}

export default Bars
