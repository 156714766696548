import React from 'react'
import { Link } from '@reach/router'

import styled from '../../../../styles/styled'
import Text from '../../../../components/Text'

const StyledContainer = styled.div`
  display: flex;
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navy};
`

const StyledLink = styled(Link)`
  padding: 8px 16px 0px;
  color: ${({ theme }) => theme.colors.darkNavy};
  margin-bottom: -1px;
  &[aria-current] {
    color: ${({ theme }) => theme.colors.blue};
    border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  }
`

const StyledText = styled(Text)`
  text-transform: uppercase;
`

const Tabs = () => (
  <StyledContainer>
    <StyledLink to="history" replace>
      <StyledText weight={600} size={13}>
        Détails des matchs
      </StyledText>
    </StyledLink>
    <StyledLink to="overview" replace>
      <StyledText weight={600} size={13}>
        Statistiques détaillées
      </StyledText>
    </StyledLink>
  </StyledContainer>
)

export default Tabs
