import React, { Component } from 'react'
import { Router, RouteComponentProps } from '@reach/router'
import { connect } from 'react-redux'

import styled from '../../styles/styled'
import Container from '../../components/Container'

import { RootState } from '../../store/reducer'
import { PlayersState } from '../../store/players/reducer'
import { fetchPlayer } from '../../store/players/actions'

import Overview from './Overview'
import History from './History'

import PlayerCard from './components/PlayerCard'
import Tabs from './components/Tabs'

const StyledContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
`

interface PlayerProps {
  player?: PlayersState[string]
  fetchPlayer: typeof fetchPlayer
}

type OwnProps = RouteComponentProps<{ playerId: string }>

type Props = PlayerProps & OwnProps

class Player extends Component<Props> {
  componentDidMount() {
    const { playerId } = this.props
    this.props.fetchPlayer(playerId)
  }

  render() {
    const { player } = this.props

    return (
      <StyledContainer>
        <PlayerCard player={player} />
        <StyledContent>
          <Tabs />
          <Router primary={false}>
            <History path="history" />
            <Overview path="overview" />
          </Router>
        </StyledContent>
      </StyledContainer>
    )
  }
}

const mapStateToProps = ({ players }: RootState, { playerId }: OwnProps) => ({
  player: players[playerId!],
})

const mapDispatchToProps = {
  fetchPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Player)
