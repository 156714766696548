import React from 'react'
import sortBy from 'ramda/es/sortBy'
import { Event, Events } from 'shared/types'

import styled from '../../styles/styled'
import Icon from '../Icon'

const StyledEvents = styled.div`
  display: flex;
  align-items: flex-end;
`

const StyledIcon = styled(Icon)`
  & + & {
    margin-left: 4px;
  }
`

const EVENTS_ORDER: Event[] = [
  'goalNormal',
  'penaltyScored',
  'penaltyMissed',
  'assist',
  'keeperPenaltySaved',
  'tackleLastMan',
  'clearanceOffTheLine',
  'errorLeadsToGoal',
  'yellowCard',
  'secondYellow',
  'redCard',
]

const formatEvents = (events: Events): Array<[Event, number]> => {
  const sortEvents = sortBy(([eventName]) => EVENTS_ORDER.indexOf(eventName))
  return sortEvents(Object.entries(events))
}

interface MatchEventsProps {
  isManOfTheMatch: boolean
  events: Events
}

const MatchEvents = ({
  isManOfTheMatch,
  events,
  ...props
}: MatchEventsProps) => {
  const sortedEvents = formatEvents(events)
  return (
    <StyledEvents {...props}>
      {isManOfTheMatch && <StyledIcon name="isManOfTheMatch" />}
      {sortedEvents.map(
        ([eventName, eventValue]) =>
          eventValue > 0 && (
            <StyledIcon key={eventName} name={eventName} count={eventValue} />
          )
      )}
    </StyledEvents>
  )
}

export default MatchEvents
