import React from 'react'
import { MatchHistory, Player } from 'shared/types'
import { connect } from 'react-redux'

import styled from '../../styles/styled'
import { getCardinalM } from '../../helpers/display'
import { setSelectedMatch } from '../../store/matches/actions'

import MatchResult from '../MatchResult'
import Card from '../Card'
import Note from '../Note'
import Bars from '../Graph/Bars'
import { getRanking, getRankingLabel } from '../../helpers/ranking'

const StyledCard = styled(Card)`
  align-items: stretch;
  justify-content: space-between;
  & + & {
    margin-top: 24px;
  }
`

const StyledMatchResult = styled(MatchResult)`
  display: flex;
  justify-content: center;
  margin-top: 4px;
`

const StyledNote = styled(Note)`
  display: flex;
  flex-direction: column;
`

const StyledLine = styled.div`
  width: 1px;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.lightGrey};
`

interface MatchRecapProps {
  match: MatchHistory
  player: Player
  setSelectedMatch: typeof setSelectedMatch
}

const MatchRecap = ({ match, player, setSelectedMatch }: MatchRecapProps) => {
  const matchRanking = getRanking(match.ranking, 'match')

  return (
    <StyledCard
      type="shadow"
      onClick={() =>
        setSelectedMatch({ playerId: player.id, matchId: match.id })
      }
    >
      <StyledMatchResult match={match} player={player} />
      <StyledLine />
      <StyledNote value={match.ballistat} label="Note Ballistic" isBallistic />
      <Bars
        statName="ballistat"
        data={matchRanking.distribution}
        value={match.ballistat}
        label={getRankingLabel('match', matchRanking.value, player)}
      />
      <StyledLine />
      <StyledNote
        value={match.minutesPlayed}
        label="Minutes jouées"
        precision={0}
      />
    </StyledCard>
  )
}

const mapDispatchToProps = {
  setSelectedMatch,
}

export default connect(
  null,
  mapDispatchToProps
)(MatchRecap)
