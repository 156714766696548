import actionCreatorFactory from 'typescript-fsa'

import { Competition } from '../../../../server/src/services/competition'

import { ThunkResult } from '../reducer'
import { httpActionCreator } from '../helpers'
import { fetchSummary } from '../summary/actions'

const actionCreator = actionCreatorFactory()

export const setSelectedMatchday = actionCreator<number>(
  'SET_SELECTED_MATCHDAY'
)

export const decrementSelectedMatchday = (): ThunkResult<void> => (
  dispatch,
  getState
) => {
  const { competition } = getState()
  const { selectedMatchday } = competition

  if (selectedMatchday > 1) {
    const newMatchday = selectedMatchday - 1
    dispatch(setSelectedMatchday(newMatchday))
    dispatch(fetchSummary(newMatchday))
  }
}

export const incrementSelectedMatchday = (): ThunkResult<void> => (
  dispatch,
  getState
) => {
  const { competition } = getState()
  const { currentMatchday, selectedMatchday } = competition

  if (selectedMatchday < currentMatchday) {
    const newMatchday = selectedMatchday + 1
    dispatch(setSelectedMatchday(newMatchday))
    dispatch(fetchSummary(newMatchday))
  }
}

export const fetchCompetition = httpActionCreator<void, Competition>(
  'FETCH_COMPETITION',
  {
    method: 'get',
    path: () => '/competition',
  }
)
