import React from 'react'
import ContentLoader from 'react-content-loader'
import times from 'ramda/es/times'
import identity from 'ramda/es/identity'
import { PlayerMatch } from 'shared/types'

import media from '../../../../styles/media'
import styled from '../../../../styles/styled'
import ContentGroup from '../../../../components/ContentGroup'
import Card from '../../../../components/Card'
import PlayerCard from '../PlayerCard'

const SUMMARY_PLAYER_COUNT = 10

const StyledPlayers = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  ${media.tablet`
    grid-template-columns: 100%;
  `}
`

const StyledCard = styled(Card)`
  padding: 0;
  ${media.phone`
    padding: 0;
  `}
`

interface PlayerGroupProps {
  title: string
  players: PlayerMatch[]
}

const PlayerCardLoading = () => (
  <StyledCard direction="column" type="shadow">
    <ContentLoader height={180} width={470} speed={1} ariaLabel="Chargement...">
      <rect x="92" y="27" width="155" height="11" rx="4" />
      <rect x="92" y="52" width="88" height="7" rx="4" />
      <rect x="92" y="73" width="58" height="7" rx="4" />
      <rect x="370" y="74" width="80" height="7" rx="4" />
      <rect x="395" y="35" width="55" height="16" rx="4" />
      <rect x="20" y="120" width="205" height="8" rx="4" />
      <rect x="20" y="145" width="205" height="8" rx="4" />
      <circle cx="53" cy="52" r="30" />
      <circle cx="370" cy="44" r="14" />
      <circle cx="438" cy="146" r="12" />
    </ContentLoader>
  </StyledCard>
)

const PlayerGroup = ({ title, players }: PlayerGroupProps) => {
  return (
    <ContentGroup title={title} titleSize={20}>
      <StyledPlayers>
        {players.length === 0
          ? times(identity, SUMMARY_PLAYER_COUNT).map(index => (
              <PlayerCardLoading key={index} />
            ))
          : players.map(player => (
              <PlayerCard key={player.id} player={player} />
            ))}
      </StyledPlayers>
    </ContentGroup>
  )
}

export default PlayerGroup
