import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { colors } from '../../styles/theme'

interface PerformanceIconProps {
  performance: 'UP' | 'DOWN' | 'EQUAL'
  direction: 'UP' | 'DOWN'
}

const colorUp = {
  UP: colors.green,
  DOWN: colors.red,
  EQUAL: colors.blue,
}

const colorDown = {
  UP: colors.red,
  DOWN: colors.green,
  EQUAL: colors.blue,
}

const PerformanceIcon = ({
  performance,
  direction,
  ...props
}: PerformanceIconProps) => (
  <FontAwesomeIcon
    icon="triangle"
    size="xs"
    color={direction === 'UP' ? colorUp[performance] : colorDown[performance]}
    {...(performance === 'DOWN' ? { rotation: 180 } : {})}
    {...(performance === 'EQUAL' ? { rotation: 90 } : {})}
    {...props}
  />
)

export default PerformanceIcon
