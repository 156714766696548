import React from 'react'

import styled from '../../styles/styled'
import media from '../../styles/media'

import Text from '../Text'

const StyledNoteContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const StyledBallisticLogo = styled.img`
  height: 28px;
  width: 28px;
  margin-right: 8px;
  ${media.phone`
    height: 18px;
    width: 18px;
    margin-right: 4px;
  `}
`

const StyledNote = styled(Text)`
  display: flex;
  align-items: baseline;
`

export interface NoteProps {
  value: number
  isBallistic?: boolean
  precision?: number
  label?: string
  suffix?: string
}

const Note = ({
  value = 0,
  isBallistic,
  precision = 1,
  label,
  suffix,
  ...props
}: NoteProps) => (
  <div {...props}>
    <StyledNoteContainer>
      {isBallistic && (
        <StyledBallisticLogo src={require('../../images/b.svg')} alt="b" />
      )}
      <StyledNote color="darkGrey" weight={600} size={32}>
        {value.toFixed(precision)}
        {suffix && (
          <Text color="darkGrey" weight={600} size={20}>
            {suffix}
          </Text>
        )}
      </StyledNote>
    </StyledNoteContainer>

    {!!label && (
      <Text color="darkNavy" size={13} weight={500}>
        {label}
      </Text>
    )}
  </div>
)

export default Note
