import { Ranking, RankingType, Player } from 'shared/types'

import { getCardinalM } from './display'

export const getRanking = (ranking: Ranking[], type: RankingType) =>
  ranking.find(r => r.type === type)!

export const getRankingLabel = (
  type: RankingType,
  ranking: number,
  player: Player
) => {
  switch (type) {
    case 'global':
      return `${getCardinalM(ranking)} (Ligue 1)`
    case 'team':
      return `${getCardinalM(ranking)} (${player.team.tla})`
    case 'matchday':
      return `${getCardinalM(ranking)} (journée)`
    case 'match':
      return `${getCardinalM(ranking)} (match)`
  }
}
