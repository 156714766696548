import React from 'react'

import { PlayerResponse } from '../../../../../../server/src/services/players/id'

import styled from '../../../../styles/styled'
import {
  generalPosition,
  getCardinalM,
  performanceLabel,
} from '../../../../helpers/display'
import { stadiums } from '../../../../helpers/stadiums'
import { getRanking } from '../../../../helpers/ranking'

import Card from '../../../../components/Card'
import Avatar from '../../../../components/Avatar'
import Text from '../../../../components/Text'
import Note from '../../../../components/Note'
import PlayerTeam from '../../../../components/PlayerTeam'
import PerformanceIcon from '../../../../components/PerformanceIcon'

const StyledCard = styled(Card)`
  position: relative;
  width: 220px;
  min-height: 360px;
  padding: 0;
  overflow: hidden;
`

const StyledStadium = styled.div<{ stadium: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url('${({ stadium }) => stadium}') center no-repeat;
  background-size: cover;
  height: 60px;
`

const StyledAvatar = styled(Avatar)`
  margin-bottom: 10px;
`

const StyledBasicInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px 16px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const StyledBasicStats = styled.div`
  padding: 16px 12px;
`
const StyledStatRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:first-child {
    margin-bottom: 4px;
  }
`

const StyledCenterContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledPerformanceIcon = styled(PerformanceIcon)`
  margin-right: 4px;
`

const getPerformance = (ballistat: PlayerResponse['ballistat']) => {
  if (ballistat.last > ballistat.average) {
    return 'UP'
  }
  if (ballistat.last < ballistat.average) {
    return 'DOWN'
  }
  return 'EQUAL'
}

interface PlayerCardProps {
  player?: PlayerResponse
}

const PlayerCard = ({ player }: PlayerCardProps) => {
  if (!player) return <StyledCard />

  const performance = getPerformance(player.ballistat)

  return (
    <StyledCard direction="column">
      <StyledStadium stadium={stadiums[player.team.tla]} />
      <StyledBasicInfos>
        <StyledAvatar player={player} size={80} />
        <Text size={20} weight={600}>
          {player.name}
        </Text>
        <Text size={13} color="darkGrey">
          {generalPosition[player.position]}
        </Text>
        <PlayerTeam team={player.team} />
      </StyledBasicInfos>
      <StyledBasicStats>
        <StyledStatRow>
          <Text preset="label">Note</Text>
          <Note isBallistic value={player.ballistat.average} precision={2} />
        </StyledStatRow>
        <StyledStatRow>
          <Text preset="label">Tendance</Text>
          <StyledCenterContainer>
            <StyledPerformanceIcon performance={performance} direction="UP" />
            <Text size={13} weight={500} color="darkGrey">
              {performanceLabel[performance]}
            </Text>
          </StyledCenterContainer>
        </StyledStatRow>
        {player.ranking.map(({ type, value }) => (
          <StyledStatRow key={type}>
            <Text preset="label">
              {type === 'global'
                ? 'Classement (Ligue 1)'
                : `Classement (${player.team.tla})`}
            </Text>
            <Text size={13} weight={600} color="darkGrey">
              {getCardinalM(value)}
            </Text>
          </StyledStatRow>
        ))}
      </StyledBasicStats>
    </StyledCard>
  )
}

export default PlayerCard
