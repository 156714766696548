import React from 'react'
import { Match, Player } from 'shared/types'

import media from '../../styles/media'
import styled from '../../styles/styled'
import { Color } from '../../styles/theme'

import Text from '../Text'
import Badge from '../Badge'
import TeamLogo from '../TeamLogo'

const StyledMatchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledMatchResult = styled.div`
  display: flex;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  margin: 0px 4px;
  line-height: 1;
`

const StyledTeamName = styled(Text)`
  ${media.phone`
    display:none;
  `}
`

const StyledMatchdayDate = styled(Text)`
  margin-left: 4px;
  ${media.phone`
    display: none;
  `}
`

interface MatchResultProps {
  className?: string
  match: Match
  player: Player
  matchday?: number
  date?: string
}

const getWinner = ({ score, homeTeam, awayTeam }: Match) => {
  if (score.fullTime.homeTeam > score.fullTime.awayTeam) {
    return homeTeam.id
  }
  if (score.fullTime.awayTeam > score.fullTime.homeTeam) {
    return awayTeam.id
  }
  return null
}

const MatchResult = ({ className, match, player }: MatchResultProps) => {
  const { score, homeTeam, awayTeam } = match

  let color: Color
  if (score.fullTime.homeTeam === score.fullTime.awayTeam) {
    color = 'darkNavy'
  } else {
    const winnerTeam = getWinner(match)
    color = winnerTeam === player.team.id ? 'green' : 'red'
  }

  return (
    <StyledMatchContainer className={className}>
      <StyledMatchResult>
        <TeamLogo team={homeTeam} />
        <StyledTeamName size={13} weight={600}>
          {homeTeam.shortName}
        </StyledTeamName>
        <StyledBadge color={color}>
          {score.fullTime.homeTeam} - {score.fullTime.awayTeam}
        </StyledBadge>
        <TeamLogo team={awayTeam} />
        <StyledTeamName size={13} weight={600}>
          {awayTeam.shortName}
        </StyledTeamName>
      </StyledMatchResult>
      <div style={{ display: 'flex', marginTop: 4 }}>
        <Text color="darkNavy" size={13}>
          Ligue 1 — {match.matchday}ème J.
        </Text>
        <StyledMatchdayDate color="darkNavy" size={13}>
          ({new Date(match.date).toLocaleDateString()})
        </StyledMatchdayDate>
      </div>
    </StyledMatchContainer>
  )
}

export default MatchResult
