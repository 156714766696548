export const logos: Record<string, string> = {
  ASC: require('../images/logos/ASC.png'),
  ASM: require('../images/logos/ASM.png'),
  ASSE: require('../images/logos/ASSE.png'),
  DFC: require('../images/logos/DFC.png'),
  EAG: require('../images/logos/EAG.png'),
  FCGB: require('../images/logos/FCGB.png'),
  FCN: require('../images/logos/FCN.png'),
  LOSC: require('../images/logos/LOSC.png'),
  MHSC: require('../images/logos/MHSC.png'),
  NO: require('../images/logos/NO.png'),
  OGC: require('../images/logos/OGC.png'),
  OL: require('../images/logos/OL.png'),
  OM: require('../images/logos/OM.png'),
  PSG: require('../images/logos/PSG.png'),
  RCSA: require('../images/logos/RCSA.png'),
  SCO: require('../images/logos/SCO.png'),
  SDR: require('../images/logos/SDR.png'),
  SMC: require('../images/logos/SMC.png'),
  SRFC: require('../images/logos/SRFC.png'),
  TFC: require('../images/logos/TFC.png'),
}
