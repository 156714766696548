import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'

import { RootState } from '../../../../store/reducer'
import styled from '../../../../styles/styled'
import { colors } from '../../../../styles/theme'
import Text from '../../../../components/Text'
import { getCardinalF } from '../../../../helpers/display'
import {
  decrementSelectedMatchday,
  incrementSelectedMatchday,
} from '../../../../store/competition/actions'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`

const StyledIconContainer = styled.div`
  width: 18px;
  display: flex;
`

const StyledIcon = styled.button`
  display: flex;
  justify-content: center;
  flex: 1;
  cursor: pointer;
  padding: 4px;
  background: none;
  border: none;
  outline: none;
  &:hover {
    opacity: 0.6;
  }
`

const StyledText = styled(Text)`
  text-align: center;
  width: 110px;
`

interface MatchDaySelectorProps {
  matchday: number
  currentMatchday: number
  decrementSelectedMatchday: () => void
  incrementSelectedMatchday: () => void
}

const MatchDaySelector = ({
  matchday,
  currentMatchday,
  decrementSelectedMatchday,
  incrementSelectedMatchday,
}: MatchDaySelectorProps) => {
  if (matchday === 0) return null

  return (
    <StyledContainer>
      <StyledIconContainer>
        {matchday > 1 && (
          <StyledIcon onClick={() => decrementSelectedMatchday()}>
            <FontAwesomeIcon
              icon={['far', 'angle-left']}
              color={colors.darkNavy}
            />
          </StyledIcon>
        )}
      </StyledIconContainer>
      <StyledText weight={600} color="darkNavy" size={13}>
        {`${getCardinalF(matchday)} journée`}
      </StyledText>
      <StyledIconContainer>
        {matchday < currentMatchday && (
          <StyledIcon onClick={() => incrementSelectedMatchday()}>
            <FontAwesomeIcon
              icon={['far', 'angle-right']}
              color={colors.darkNavy}
            />
          </StyledIcon>
        )}
      </StyledIconContainer>
    </StyledContainer>
  )
}

const mapStateToProps = ({ competition }: RootState) => ({
  currentMatchday: competition.currentMatchday,
  matchday: competition.selectedMatchday,
})

const mapDispatchToProps = {
  decrementSelectedMatchday,
  incrementSelectedMatchday,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MatchDaySelector)
