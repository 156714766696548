import React, { useState } from 'react'
import { Player } from 'shared/types'

import { API_BASE_URL } from '../../store'
import styled from '../../styles/styled'

const StyledContainer = styled.div.attrs(({ size }: AvatarProps) => ({
  width: size || 64,
  height: size || 64,
}))`
  position: relative;
  flex-shrink: 0;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(172, 172, 172, 0.5);
`

const StyledName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.darkNavy};
`

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const getInitials = (name: string) => {
  const parts = name.split(' ')
  const firstName = parts.shift()
  const lastName = parts.pop()

  return [firstName, lastName]
    .filter(Boolean)
    .map(n => n && n[0])
    .join(' ')
}

interface AvatarProps {
  size?: number
  player: Player
}

const Avatar = ({ player, ...props }: AvatarProps) => {
  const [hasError, setError] = useState(false)

  return (
    <StyledContainer {...props}>
      {hasError ? (
        <StyledName>{getInitials(player.name)}</StyledName>
      ) : (
        <StyledImage
          src={`${API_BASE_URL}/players/${player.id}/avatar`}
          alt={player.name}
          onError={() => setError(true)}
        />
      )}
    </StyledContainer>
  )
}

export default Avatar
