import React, { CSSProperties, ReactNode } from 'react'

import styled from '../../styles/styled'
import { Color } from '../../styles/theme'
import media from '../../styles/media'

const mobileFontSize = {
  13: 12,
  16: 14,
  20: 18,
  24: 20,
  32: 24,
}

const StyledDiv = styled.div<TextProps>`
  color: ${({ theme, color }) => color && theme.colors[color]};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size}px;
  ${media.phone`
    font-size: ${({ size }: TextProps) => size && mobileFontSize[size]}px;
  `}
`

export type FontSize = 13 | 16 | 20 | 24 | 32
export type FontWeight = 400 | 500 | 600

interface TextProps {
  children: ReactNode
  style?: CSSProperties
  color?: Color
  weight?: 400 | 500 | 600
  size?: FontSize // 20 | 25 | 30 | 32 | 46
  preset?: keyof typeof presets
}

const presets = {
  label: { color: 'darkNavy', weight: 500, size: 13 },
}

const Text = ({ children, preset, ...props }: TextProps) => {
  const presetProps = preset ? presets[preset] : {}

  return (
    <StyledDiv {...props} {...presetProps}>
      {children}
    </StyledDiv>
  )
}

Text.defaultProps = {
  weight: 400,
  size: 16,
} as TextProps // workaround

export default Text
