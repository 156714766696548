export const generalPosition = {
  Attacker: 'Attaquant',
  Midfielder: 'Milieu',
  Defender: 'Défenseur',
  Goalkeeper: 'Gardien',
}

export const matchPosition = {
  AMC: 'Milieu offensif central',
  AML: 'Milieu offensif gauche',
  AMR: 'Milieu offensif droit',
  DC: 'Défenseur central',
  DL: 'Défenseur latéral gauche',
  DR: 'Défenseur latéral droit',
  DMC: 'Milieu défensif central',
  DML: 'Milieu défensif gauche',
  DMR: 'Milieu défensif droit',
  FW: 'Avant-centre',
  FWR: 'Attaquant droit',
  FWL: 'Attaquant gauche',
  GK: 'Gardien',
  MC: 'Milieu central',
  ML: 'Milieu gauche',
  MR: 'Milieu droit',
  Sub: 'Remplaçant',
}

export const eventName = {
  isManOfTheMatch: 'Homme du match',
  penaltyScored: 'Penalty marqué',
  goalOwn: 'But contre son camp',
  goalNormal: 'But marqué',
  yellowCard: 'Carton jaune',
  secondYellow: 'Carton jaune',
  redCard: 'Carton rouge',
  assist: 'Passe décisive',
  clearanceOffTheLine: 'Dégagement sur la ligne',
  errorLeadsToGoal: 'Erreur menant à un but',
  keeperPenaltySaved: 'Penalty arrêté',
  penaltyMissed: 'Penalty manqué',
  tackleLastMan: 'Tacle dernier défenseur',
}

export const statName = {
  // attack
  shotsTotal: 'Tirs',
  shotOnTarget: 'Tirs cadrés',
  keyPassTotal: 'Occasions créées',
  dribbleTotal: 'Dribbles',
  dribbleWon: 'Dribbles réussis',
  foulGiven: 'Fautes subies',
  offsideGiven: 'Hors jeux',
  turnover: 'Ballons perdus',

  // defence
  foulCommitted: 'Fautes',
  tackleTotalAttempted: 'Tacles',
  tackleWon: 'Tacles réussis',
  interceptionAll: 'Interceptions',
  clearanceTotal: 'Dégagements',
  shotBlocked: 'Tirs contrés',
  offsideProvoked: 'Hors jeux provoqués',

  // passes
  totalPasses: 'Passes',
  passSuccessInMatch: 'Précision des passes',
  passCrossTotal: 'Centres',
  passCrossAccurate: 'Centres réussis',
  passLongBallTotal: 'Passes longues',
  passLongBallAccurate: 'Passes longues réussies',
  passThroughBallTotal: 'Passes en profondeur',
  passThroughBallAccurate: 'Passes en profondeur réussies',
}

export const performanceLabel = {
  UP: 'En hausse',
  DOWN: 'En baisse',
  EQUAL: 'Constant',
}

export const getCardinalM = (count: number) => {
  const cardinal = count === 1 ? 'er' : 'ème'
  return `${count}${cardinal}`
}

export const getCardinalF = (count: number) => {
  const cardinal = count === 1 ? 'ère' : 'ème'
  return `${count}${cardinal}`
}

export const withArticle = (word: string) => {
  const vowels = ['a', 'e', 'i', 'o', 'u', 'y']

  if (vowels.includes(word[0].toLowerCase())) {
    return `de l’${word}`
  }
  return `du ${word}`
}
