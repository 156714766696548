import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import path from 'ramda/es/path'
import { MatchHistory } from 'shared/types'

import { RootState } from '../../../store/reducer'
import { PlayersState } from '../../../store/players/reducer'
import { fetchPlayerHistory } from '../../../store/history/actions'

import ContentGroup from '../../../components/ContentGroup'
import MatchRecap from '../../../components/MatchRecap'
import DetailedStats from '../../../components/DetailedStats'

import KeyStats from './KeyStats'
import { connect } from 'react-redux'

interface OverviewProps {
  player?: PlayersState[string]
  lastMatch?: MatchHistory
  fetchPlayerHistory: typeof fetchPlayerHistory
}

type OwnProps = RouteComponentProps<{ playerId: string }>

type Props = OverviewProps & OwnProps

const Overview: FC<Props> = ({
  playerId,
  player,
  lastMatch,
  fetchPlayerHistory,
}) => {
  useEffect(() => {
    fetchPlayerHistory(playerId)
  }, [playerId])

  if (!lastMatch || !player) return null

  return (
    <>
      <ContentGroup title="Dernier match joué">
        <MatchRecap match={lastMatch} player={player} />
      </ContentGroup>
      <KeyStats />
      <DetailedStats player={player} />
    </>
  )
}

const mapStateToProps = (
  { players, history }: RootState,
  { playerId }: OwnProps
) => ({
  player: players[playerId!],
  lastMatch: path<MatchHistory>([playerId!, 0], history),
})

const mapDispatchToProps = {
  fetchPlayerHistory,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview)
