import React from 'react'

import styled from '../../../../styles/styled'

import ContentGroup from '../../../../components/ContentGroup'
import Note from '../../../../components/Note'
import Bars from '../../../../components/Graph/Bars'
import Card from '../../../../components/Card'

const StyledInnerCardContainer = styled.div`
  display: flex;
  flex: 1;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
  &:not(:last-child) {
    > div {
      padding-bottom: 20px;
    }
  }
  &:not(:first-child) {
    > div {
      padding-top: 20px;
    }
  }
`

const StyledInnerCard = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  &:not(:first-child) {
    padding-left: 20px;
  }
  &:not(:last-child) {
    padding-right: 20px;
    border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`

const KeyStats = () => (
  <ContentGroup title="Chiffres clés">
    <Card direction="column">
      <StyledInnerCardContainer>
        <StyledInnerCard>
          <Note value={7} label="Buts" precision={0} />
          <Bars label="1er" statName="ballistat" value={7} />
        </StyledInnerCard>
        <StyledInnerCard>
          <Note value={3} label="Passes décisives" precision={0} />
          <Bars label="3ème" statName="ballistat" value={3} />
        </StyledInnerCard>
      </StyledInnerCardContainer>
      <StyledInnerCardContainer>
        <StyledInnerCard>
          <Note value={2} label="Cartons" precision={0} />
          <Bars label="1er" statName="ballistat" value={2} />
        </StyledInnerCard>
        <StyledInnerCard>
          <Note value={560} label="Minutes jouées" precision={0} />
          <Bars label="5ème" statName="ballistat" value={560} />
        </StyledInnerCard>
      </StyledInnerCardContainer>
    </Card>
  </ContentGroup>
)

export default KeyStats
