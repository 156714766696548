import React from 'react'
import { Team } from 'shared/types'

import styled from '../../styles/styled'
import { logos } from '../../helpers/logos'

const StyledLogoContainer = styled.div.attrs(({ size }: { size?: number }) => ({
  width: size || 20,
  height: size || 20,
}))`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`

const StyledLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
`

interface TeamLogoProps {
  size?: number
  team: Team
}

const TeamLogo = ({ team, ...props }: TeamLogoProps) => (
  <StyledLogoContainer {...props}>
    <StyledLogo src={logos[team.tla]} alt={team.tla} />
  </StyledLogoContainer>
)

export default TeamLogo
