import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ActionType } from 'typesafe-actions'
import { MatchHistory } from 'shared/types'

import * as actions from './actions'

export type HistoryAction = ActionType<typeof actions>

export interface HistoryState {
  [playerId: string]: MatchHistory[]
}

const defaultState: HistoryState = {}

const reducer = reducerWithInitialState(defaultState).case(
  actions.fetchPlayerHistory.done,
  (state, { result: { history }, params: playerId }) => ({
    ...state,
    [playerId]: history,
  })
)

export default reducer
