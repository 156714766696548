import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ActionType } from 'typesafe-actions'

import { PlayersSummaryResponse } from '../../../../server/src/services/players/summary'

import * as actions from './actions'

export type SummaryAction = ActionType<typeof actions>

export interface SummaryState {
  [matchday: number]: PlayersSummaryResponse['summary']
}

const defaultState: SummaryState = {}

const reducer = reducerWithInitialState(defaultState).case(
  actions.apiFetchSummary.done,
  (state, { result: { matchday, summary } }) => ({
    ...state,
    [matchday]: summary,
  })
)

export default reducer
