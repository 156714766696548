import React, { useState, useEffect, ReactNode } from 'react'
import { connect } from 'react-redux'
import pathOr from 'ramda/es/pathOr'
import takeLast from 'ramda/es/takeLast'
import allStatistics from 'shared/dist/statistics'
import {
  StatisticType,
  StatHistory,
  Player,
  PlayerStatistic,
} from 'shared/types'

import styled from '../../styles/styled'
import media from '../../styles/media'

import { RootState } from '../../store/reducer'
import { fetchMatchStatistics } from '../../store/matches/actions'
import { statName, getCardinalM, performanceLabel } from '../../helpers/display'

import ContentGroup from '../../components/ContentGroup'
import Note from '../../components/Note'
import Line from '../../components/Graph/Line'
import Bars from '../../components/Graph/Bars'
import Card from '../../components/Card'
import Badge from '../../components/Badge'
import PerformanceIcon from '../PerformanceIcon'
import { fetchPlayerOverviewStats } from '../../store/players/actions'
import { getRankingLabel } from '../../helpers/ranking'

const StyledBadgeContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  ${media.tablet`
    margin-bottom: 0px;
  `}
`

const StyledBadge = styled(Badge)`
  cursor: pointer;
  & + & {
    margin-left: 12px;
  }
  :first-letter {
    text-transform: uppercase;
  }
`

const StyledInnerCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 140px);
  justify-content: space-between;
  &:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
  &:not(:first-child) {
    padding-top: 20px;
  }
  ${media.phone`
    grid-template-columns: repeat(2, 50%);
    grid-row-gap: 15px;
  `}
`

const StyledNote = styled(Note)`
  width: 140px;
  display: flex;
  flex-direction: column;
`

const StyledPerformanceIcon = styled(PerformanceIcon)`
  margin-left: 4px;
`

const StyledPlaySpan = styled.span`
  ${media.phone`
    display: none;
  `}
`

const StyledLoaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 30px;
  }
`

const getPerformance = (history: StatHistory) => {
  if (history.length < 2) {
    return 'EQUAL'
  }

  const [previousMatch, currentMatch] = takeLast(2, history)
  const { value: previousValue } = previousMatch
  const { value: currentValue } = currentMatch

  if (previousValue > currentValue) {
    return 'DOWN'
  }

  if (currentValue > previousValue) {
    return 'UP'
  }

  return 'EQUAL'
}

const tabs: Array<{ name: ReactNode; type: StatisticType }> = [
  {
    name: (
      <span>
        <StyledPlaySpan>jeu </StyledPlaySpan>offensif
      </span>
    ),
    type: 'attack',
  },
  {
    name: (
      <span>
        <StyledPlaySpan>jeu </StyledPlaySpan>défensif
      </span>
    ),
    type: 'defence',
  },
  {
    name: (
      <span>
        <StyledPlaySpan>jeu de </StyledPlaySpan>passes
      </span>
    ),
    type: 'passes',
  },
]

interface ReduxProps {
  fetchMatchStatistics: typeof fetchMatchStatistics
  fetchPlayerOverviewStats: typeof fetchPlayerOverviewStats
  stats: {
    attack: PlayerStatistic[]
    defence: PlayerStatistic[]
    passes: PlayerStatistic[]
  }
}

interface OwnProps {
  player: Player
  matchId?: string
}

type DetailedStatsProps = ReduxProps & OwnProps

const DetailedStats = ({
  player,
  matchId,
  fetchMatchStatistics,
  fetchPlayerOverviewStats,
  stats,
}: DetailedStatsProps) => {
  const [activeTab, setActiveTab] = useState<StatisticType>('attack')

  useEffect(() => {
    if (matchId) {
      fetchMatchStatistics({ playerId: player.id, matchId, type: activeTab })
    } else {
      fetchPlayerOverviewStats({ playerId: player.id, type: activeTab })
    }
  }, [player.id, matchId, activeTab])

  const displayedStats = stats[activeTab]

  return (
    <ContentGroup title="Statistiques détaillées">
      <Card direction="column">
        <StyledBadgeContainer>
          {tabs.map(({ name, type }) => (
            <StyledBadge
              key={type}
              color={activeTab === type ? 'darkBlue' : 'lightGrey'}
              isBig
              onClick={() => setActiveTab(type)}
            >
              {name}
            </StyledBadge>
          ))}
        </StyledBadgeContainer>
        {!displayedStats ? (
          <StyledLoaderContainer>
            <img src={require('../../images/loader.svg')} alt="loader" />
          </StyledLoaderContainer>
        ) : (
          displayedStats.map(({ name, value, ranking, history }) => {
            const performance = getPerformance(history)
            const { performance: direction, suffix } = allStatistics.find(
              s => s.name === name
            )!

            return (
              <StyledInnerCardContainer key={name}>
                <StyledNote
                  value={value}
                  label={statName[name]}
                  precision={matchId ? 0 : 1}
                  suffix={suffix}
                />
                <Line
                  data={history}
                  label={
                    <span>
                      {performanceLabel[performance]}{' '}
                      <StyledPerformanceIcon
                        performance={performance}
                        direction={direction}
                      />
                    </span>
                  }
                />
                {ranking.map(({ type, value: rankingValue, distribution }) => (
                  <Bars
                    key={type}
                    statName={name}
                    data={distribution}
                    value={value}
                    label={getRankingLabel(type, rankingValue, player)}
                  />
                ))}
              </StyledInnerCardContainer>
            )
          })
        )}
      </Card>
    </ContentGroup>
  )
}

const mapStateToProps = (
  { matches, players }: RootState,
  { player, matchId }: OwnProps
) => {
  if (matchId) {
    const { selectedMatch, matchesByPlayers } = matches
    return {
      stats: selectedMatch
        ? pathOr(
            {},
            [selectedMatch.playerId, selectedMatch.matchId, 'stats'],
            matchesByPlayers
          )
        : {},
    }
  }

  return { stats: pathOr({}, [player.id, 'stats'], players) }
}

const mapDispatchToProps = {
  fetchMatchStatistics,
  fetchPlayerOverviewStats,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedStats)
