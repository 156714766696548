import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ActionType } from 'typesafe-actions'
import assocPath from 'ramda/es/assocPath'
import { PlayerStatistic } from 'shared/types'

import { PlayerResponse } from '../../../../server/src/services/players/id'

import * as actions from './actions'

export type PlayersAction = ActionType<typeof actions>

export interface PlayersState {
  [playerId: string]: PlayerResponse & {
    stats: {
      attack: PlayerStatistic[]
      defence: PlayerStatistic[]
      passes: PlayerStatistic[]
    }
  }
}

const defaultState: PlayersState = {}

const reducer = reducerWithInitialState(defaultState)
  .case(
    actions.fetchPlayer.done,
    (state, { result: player, params: playerId }) => ({
      ...state,
      [playerId]: { ...state[playerId], ...player },
    })
  )
  .case(actions.fetchPlayerOverviewStats.done, (state, { result, params }) =>
    assocPath([params.playerId, 'stats', params.type], result, state)
  )

export default reducer
