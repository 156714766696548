import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ActionType } from 'typesafe-actions'
import assocPath from 'ramda/es/assocPath'
import { PlayerMatch, PlayerStatistic } from 'shared/types'

import * as actions from './actions'

export type MatchesAction = ActionType<typeof actions>

export interface MatchesState {
  selectedMatch: { matchId: string; playerId: string } | null
  matchesByPlayers: {
    [playerId: string]: {
      [matchId: string]: {
        details: PlayerMatch
        stats: {
          attack: PlayerStatistic[]
          defence: PlayerStatistic[]
          passes: PlayerStatistic[]
        }
      }
    }
  }
}

const defaultState: MatchesState = {
  selectedMatch: null,
  matchesByPlayers: {},
}

const reducer = reducerWithInitialState(defaultState)
  .case(actions.setSelectedMatch, (state, match) => ({
    ...state,
    selectedMatch: match,
  }))
  .case(actions.fetchMatchDetails.done, (state, { result, params }) =>
    assocPath(
      ['matchesByPlayers', params.playerId, params.matchId, 'details'],
      result,
      state
    )
  )
  .case(actions.fetchMatchStatistics.done, (state, { result, params }) =>
    assocPath(
      [
        'matchesByPlayers',
        params.playerId,
        params.matchId,
        'stats',
        params.type,
      ],
      result,
      state
    )
  )

export default reducer
