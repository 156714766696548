import { PlayerMatchHistoryResponse } from '../../../../server/src/services/players/match/history'

import { httpActionCreator } from '../helpers'

export const fetchPlayerHistory = httpActionCreator<
  string,
  PlayerMatchHistoryResponse
>('FETCH_PLAYER_HISTORY', {
  method: 'get',
  path: playerId => `/players/${playerId}/matches`,
})
