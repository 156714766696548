import React from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from '../../styles/styled'

import { CONTAINER_WIDTH } from '../Container'

ReactModal.setAppElement('#root')

const StyledClose = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
  margin-left: 8px;
  margin-top: 24px;
`

const StyledCloseContainer = styled.div`
  position: absolute;
  max-width: ${CONTAINER_WIDTH}px;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  pointer-events: none;
`

const modalClassName = 'modal'
const overlayClassName = 'overlay'

const StyledModal = styled(
  ({ className: portalClassName, modalClassName: className, ...props }) => (
    <ReactModal
      className={className}
      portalClassName={portalClassName}
      {...props}
    />
  )
).attrs({
  modalClassName,
  overlayClassName,
})`
  .${modalClassName} {
    position: static;
    background: ${({ theme, background }) =>
      background === 'white' ? theme.colors.white : theme.colors.lightGrey};
    margin: 90px 0px;
    width: 100%;
    max-width: ${(80 * CONTAINER_WIDTH) / 100}px;
    outline: none;
    border-radius: 4px;
    padding: 20px;
  }
  .${overlayClassName} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    background: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
`

type Props = {
  background?: 'white' | 'grey'
} & ReactModal['props']

const Modal = ({ children, ...props }: Props) => (
  <StyledModal {...props}>
    <StyledCloseContainer>
      <StyledClose onClick={props.onRequestClose}>
        <FontAwesomeIcon icon={['fal', 'times']} size="lg" />
      </StyledClose>
    </StyledCloseContainer>
    {children}
  </StyledModal>
)

Modal.defaultProps = {
  background: 'white',
}

export default Modal
